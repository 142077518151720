import React, { useState } from 'react';
import { useAuthentication } from './AuthenticationContext';

const LoginPopup = () => {
  const { setShowLoginPopup, handleLogin, handleRegister } = useAuthentication();
  const [isRegistering, setIsRegistering] = useState(false);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async () => {
    if (isRegistering) {
      await handleRegister(email, username, password);
    } else {
      await handleLogin(username, password);
    }
    setShowLoginPopup(false);
  };

  const toggleMode = () => setIsRegistering(!isRegistering);

  return (
    <div className="login-popup">
      <button onClick={() => setShowLoginPopup(false)}>Close</button>
      <h2>{isRegistering ? 'Register' : 'Login'}</h2>

      {isRegistering && (
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      )}
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleSubmit}>
        {isRegistering ? 'Register' : 'Login'}
      </button>
      <button onClick={toggleMode}>
        {isRegistering ? 'Have an account? Login' : 'Need an account? Register'}
      </button>
    </div>
  );
};

export default LoginPopup;
