import React, { useState } from 'react';
import { useCalculations } from '../CalculationsContext';

const HoldingYears = () => {
  const { holdingYears, setHoldingYears } = useCalculations();

  return (
    <div>
      <h5>Holding Years</h5>
      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Number of Years:</label>
          <input 
            type="number" 
            value={holdingYears} 
            onChange={(e) => setHoldingYears(Number(e.target.value))} 
            className="form-control ml-2 input-field"
          />
        </div>
      </div>
    </div>
  );
};

export default HoldingYears;
