import React from 'react';
import { useCalculations } from '../CalculationsContext';

const MonthlyKPIs = () => {
  const { monthlyGrossIncome, setMonthlyGrossIncome } = useCalculations();
  const { monthlyOperatingExpenses, setMonthlyOperatingExpenses } = useCalculations();
  const { monthlyNOI, setMonthlyNOI } = useCalculations();
  const { monthlyCashFlow, setMonthlyCashFlow } = useCalculations();

  return (
    <div className='monthly-kpis'>
      <div>
        <h4>Monthly Key Performance Indicators</h4>
        <div>
          <strong>Monthly Gross Income:</strong> ${monthlyGrossIncome.toFixed(2)}
        </div>
        <div>
          <strong>Monthly Operating Expenses:</strong> ${monthlyOperatingExpenses.toFixed(2)}
        </div>
        <div>
          <strong>Monthly Net Operating Income (NOI):</strong> ${monthlyNOI.toFixed(2)}
        </div>
        <div>
          <strong>Monthly Cash Flow:</strong> ${monthlyCashFlow.toFixed(2)}
        </div>
      </div>
    </div>
  );
};

export default MonthlyKPIs;

