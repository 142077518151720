import React from 'react';
import axios from 'axios';
import { useCalculations } from '../CalculationsContext';
import { fetchSavedProperties } from './InvestmentProfiles';
import { useAuthentication } from './AuthenticationContext';
import { useForecast } from './ForecastContext';
import config from '../config';

const SavePropertyButton = ({ setInvestmentProfiles }) => {
    const calculations = useCalculations();
    const { forecastId, forecastDate } = useForecast();
    const { isAuthenticated, propertyCount, handleGuestAction, currentUserId, guestUserId } = useAuthentication();

    const generateTemporaryPropertyId = () => {
        return {
            propertyId: Date.now().toString(),
            timestamp: Date.now()
        };
    };
  
    const gatherPropertyData = () => {
        const temporaryIdAndTimestamp = generateTemporaryPropertyId();
        const data = { ...temporaryIdAndTimestamp, ...calculations.gatherPropertyData() };
        return Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== null && value !== undefined));
    };
  
    const saveProperty = async () => {
      if (!isAuthenticated) {
          handleGuestAction(); 
          if (propertyCount >= 2) {
              console.warn("Guest users can only save up to 2 properties.");
              return;
          }
      }
  
      const userIdToUse = isAuthenticated ? currentUserId : guestUserId;
      const propertyData = { isAuthenticated, userId: userIdToUse, ...gatherPropertyData(), forecastId, forecastDate };
      console.log("Property data before sending:", propertyData);
  
      try {
          const response = await axios.post(`${config.API_BASE_URL}/save_property`, propertyData, { withCredentials: true });
          console.log("Data saved:", response.data);
          fetchSavedProperties(setInvestmentProfiles, userIdToUse);
      } catch (error) {
          console.error("Error saving data:", error.response ? error.response.data.detail : error);
      }
  };
  
  return <button onClick={saveProperty}>Save Property</button>;
};

export default SavePropertyButton;