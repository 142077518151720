import React, { useState } from 'react';
import newLogo from './new-logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Resizable } from 'react-resizable';
import InvestmentProfiles from './components/InvestmentProfiles';
import PurchaseCosts from './components/PurchaseCosts';
import Renovation from './components/Renovation';
import MonthlyIncome from './components/MonthlyIncome';
import MonthlyExpenses from './components/MonthlyExpenses';
import AnnualExpenses from './components/AnnualExpenses';
import HoldingYears from './components/HoldingYears';
import MonthlyKPIs from './components/MonthlyKPIs';
import AnnualKPIs from './components/AnnualKPIs';
import AnnualizedKPIs from './components/AnnualizedKPIs';
import SavePropertyButton from './components/SavePropertyButton';
import { CalculationsProvider } from './CalculationsContext';
import { useAuthentication } from './components/AuthenticationContext';
import LoginPopup from './components/LoginPopup';
import PriceEstimateSearch from './components/PriceEstimateSearch';
import ForecastChart from './components/ForecastChart';
import NavBar from './components/NavBar';
import Layout from './components/Layout';


const AppContent = () => {
  const [investmentProfiles, setInvestmentProfiles] = useState([]);
  const { showLoginPopup, currentUserId } = useAuthentication();
  const [showCalculatorCard, setShowCalculatorCard] = useState(true);

  const toggleCalculatorCard = (shouldShow) => {
    setShowCalculatorCard(shouldShow);
  };

  return (
    <Layout>
      <div className="App">
        <NavBar />
        <div className="container-fluid">
          <div className="row">
            {showCalculatorCard && (
              <div className="col-md-2 navy-blue-background">
                <Resizable 
                  width={320}
                  height={600}
                  handleSize={[20, 20]}
                >
                  <div className="calculator-card">
                    <CalculationsProvider>
                      <div className="App-content">
                        <PriceEstimateSearch />
                        <ForecastChart />
                        <PurchaseCosts />
                        <Renovation />
                        <MonthlyIncome />
                        <MonthlyExpenses />
                        <AnnualExpenses />
                        <HoldingYears />
                        <div className="kpi-section">
                          <h2>Key Performance Indicators</h2>
                          <MonthlyKPIs />
                          <AnnualKPIs />
                          <AnnualizedKPIs />
                          <div className="save-button-container">
                            <SavePropertyButton 
                              setInvestmentProfiles={setInvestmentProfiles}
                              currentUserId={currentUserId}
                            />
                            {showLoginPopup && <LoginPopup />}
                          </div>
                        </div>
                      </div>
                    </CalculationsProvider>
                  </div>
                </Resizable>
              </div>
            )}
            <div className={showCalculatorCard ? "col-md-10" : "col-md-12"}>
              <InvestmentProfiles
                investmentProfiles={investmentProfiles}
                setInvestmentProfiles={setInvestmentProfiles}
                currentUserId={currentUserId}
                toggleCalculatorCard={toggleCalculatorCard} // Pass the toggle function as a prop
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );  
};

export default AppContent; 
