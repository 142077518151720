import React from 'react';
import { useCalculations } from '../CalculationsContext';

const AnnualKPIs = () => {
  const { annualCashFlow, setAnnualCashFlow } = useCalculations();
  const { cashOnCashReturn, setCashOnCashReturn } = useCalculations();
  const { annualGrossIncome, setAnnualGrossIncome } = useCalculations();
  const { annualOperatingExpenses, setAnnualOperatingExpenses } = useCalculations();
  const { firstYearPropertyAppreciation, setFirstYearPropertyAppreciation } = useCalculations();
  const { annualRentAppreciation, setAnnualRentAppreciation } = useCalculations();
  

  return (
    <div className='annual-kpis'>
        <div>
            <h4>Annual Key Performance Indicators</h4>
            <div>
                <strong>Annual Cash Flow:</strong> ${annualCashFlow.toFixed(2)}
            </div>
            <div>
                <strong>Cash-on-Cash Return:</strong> ${cashOnCashReturn.toFixed(2)}
            </div>
            <div>
                <strong>Annual Gross Income:</strong> ${annualGrossIncome.toFixed(2)}
            </div>
            <div>
                <strong>Annual Operating Expenses:</strong> ${annualOperatingExpenses.toFixed(2)}
            </div>
            <div>
                <strong>First Year Property Appreciation:</strong> ${firstYearPropertyAppreciation.toFixed(2)}
            </div>
            <div>
                <strong>First Year Rent Appreciation:</strong> ${annualRentAppreciation.toFixed(2)}
            </div>
        </div>
    </div>
  );
};

export default AnnualKPIs;
