import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthenticationContext } from './AuthenticationContext';
import ForecastChart from './ForecastChart';
import Sidebar from './Sidebar';
import newLogo from '../new-logo.svg';
import KpiScorecard from './KpiScorecard';
import ForecastPercentageDisplay from './ForecastPercentageDisplay'; 
import Layout from './Layout';
import config from '../config';

const ForecastingView = () => {
    const [allProfiles, setAllProfiles] = useState([]); // This will hold all profiles
    const [forecastResults, setForecastResults] = useState({});
    const [selectedAnalysisId, setSelectedAnalysisId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { currentUserId, guestUserId } = useContext(AuthenticationContext);
    
   

    // Fetch all profiles for the user
    useEffect(() => {
        const fetchProfilesAndForecasts = async () => {
            const userIdToUse = currentUserId || guestUserId; // Use guestUserId if currentUserId is null/undefined
            console.log('userIdToUse: ', userIdToUse);
            setIsLoading(true);
            setError(null);
            try {
                // Fetch all investment profiles
                const profilesResponse = await axios.get(`${config.API_BASE_URL}/get_investment_profiles?user_id=${userIdToUse}`);
                setAllProfiles(profilesResponse.data);

                // Fetch all forecasts for the profiles
                await fetchForecasts(profilesResponse.data.map(p => p.analysisId), profilesResponse.data);
            } catch (err) {
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProfilesAndForecasts();
    }, [currentUserId, guestUserId]);

    const fetchForecasts = async (profilesToFetch, profilesData) => {
        let results = {};

        for (let profile of profilesData) {
            // Check if forecast data is available for the profile
            if (profile.forecastId && profile.forecastDate) {
                try {
                    const forecastResponse = await axios.get(`${config.API_BASE_URL}/get-forecast/${profile.forecastId}?forecast_date=${profile.forecastDate}`);
                    results[profile.analysisId] = {
                        ...forecastResponse.data,
                        propertyAddress: profile.propertyAddress,
                        propertyName: profile.propertyName,
                        propperyPrice: profile.propertyPrice,
                        totalROI: profile.totalROI,
                        monthlyCashFlow: profile.monthlyCashFlow,
                        afterRepairValue: profile.afterRepairValue,
                        capRate: profile.capRate,
                        holdingYears: profile.holdingYears,
                        downPayment: profile.downPayment,
                        renovationCost: profile.renovationCost,

                    };
                } catch (err) {
                    console.log(`Error fetching forecast for profile ID ${profile.analysisId}:`, err);
                    results[profile.analysisId] = { noForecast: true, propertyAddress: profile.propertyAddress };
                }
            }
        }

        setForecastResults(results);
        // Use the first profile's analysisId for displaying the forecast
        if (profilesToFetch.length > 0) {
            setSelectedAnalysisId(profilesToFetch[0]);
        }
    };

    if (isLoading) {
        return <div>Loading forecasts...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    
    return (
        <Layout>
            <div className="App">
                <div className="forecasting-layout"> 
                    <Sidebar analyses={allProfiles} onSelectAnalysis={setSelectedAnalysisId} selectedAnalysisId={selectedAnalysisId} />
                    <div className="forecast-main-content">
                    <h2>
                        Forecast: {
                            selectedAnalysisId && forecastResults[selectedAnalysisId] 
                            ? (
                                forecastResults[selectedAnalysisId].propertyName || forecastResults[selectedAnalysisId].propertyAddress || "Property Info Not Available"
                            )
                            : "Select a property"
                        }
                    </h2>
                        {selectedAnalysisId && forecastResults[selectedAnalysisId] && (
                            <div className="forecast-and-kpis-container">
                                <div className="chart-and-kpis">
                                    <div key={selectedAnalysisId} className="forecast-result">
                                        <h3>{forecastResults[selectedAnalysisId].propertyAddress}</h3>
                                        {forecastResults[selectedAnalysisId].noForecast ? (
                                            <p>No forecast available for this property.</p>
                                        ) : (
                                            <>
                                                {forecastResults[selectedAnalysisId].forecastValue && <ForecastChart data={forecastResults[selectedAnalysisId]} address={forecastResults[selectedAnalysisId].propertyAddress} />}
                                                
                                                <div className="forecast-model-display">
                                                    <small>Forecasting Model: {forecastResults[selectedAnalysisId].selectedModel}</small>
                                                </div>

                                                <div className="kpi-scorecard-container">
                                                    <KpiScorecard
                                                        totalROI={forecastResults[selectedAnalysisId].totalROI}
                                                        monthlyCashFlow={forecastResults[selectedAnalysisId].monthlyCashFlow}
                                                        afterRepairValue={forecastResults[selectedAnalysisId].afterRepairValue}
                                                        capRate={forecastResults[selectedAnalysisId].capRate}
                                                        holdingYears={forecastResults[selectedAnalysisId].holdingYears}
                                                        requiredCapital={parseFloat(forecastResults[selectedAnalysisId].downPayment) + (forecastResults[selectedAnalysisId].renovationCost || 0)}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="forecast-percentage-display-container">
                                    <h3>Projected Growth</h3> {/* Add this line for the title */}
                                    <ForecastPercentageDisplay
                                        forecastValue={forecastResults[selectedAnalysisId].forecastValue}
                                        percentIncreases={forecastResults[selectedAnalysisId].percentIncreases}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );      
};

export default ForecastingView;