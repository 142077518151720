import React from 'react';

const Kpi = ({ label, value }) => (
  <div className="kpi">
    <label>{label}</label>
    <span>{value}</span>
  </div>
);

const KpiScorecard = ({ totalROI, monthlyCashFlow, afterRepairValue, capRate, holdingYears, requiredCapital }) => {
  // Helper function to format numbers as currency
  const formatCurrency = (value) => `$${Math.round(value).toLocaleString()}`;

  // Helper function to format percentages, rounded to the nearest tens place
  const formatPercentage = (value) => `${Math.round(value * 10) / 10}%`;

  return (
    <div className="kpi-scorecard-grid">
      <Kpi label="Total ROI" value={formatPercentage(totalROI)} />
      <Kpi label="Monthly Cash Flow" value={formatCurrency(monthlyCashFlow)} />
      <Kpi label="After Repair Value" value={formatCurrency(afterRepairValue)} />
      <Kpi label="Cap Rate" value={formatPercentage(capRate)} />
      <Kpi label="Holding Years" value={holdingYears.toString()} />
      <Kpi label="Required Capital" value={formatCurrency(requiredCapital)} />
    </div>
  );
};

export default KpiScorecard;
