import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ForecastChart = ({ data, address }) => {
  // Check if data is available
  if (!data || !data.forecastValue || !data.percentIncreases) {
    return <p>No forecast data available for {address}.</p>;
  }

  // Extract the forecast values and percentage increases
  const labels = Object.keys(data.forecastValue).map((month, index) => `${month} Months`);
  const dataPoints = Object.values(data.forecastValue);
  const percentIncreases = Object.values(data.percentIncreases);

  // Chart data setup
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Forecast Price',
        data: dataPoints,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
    ],
  };

  // Chart options setup, including custom tooltip
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Forecast Chart',
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            let value = context.parsed.y;
            let percentage = percentIncreases[context.dataIndex];
            if (value) {
              label += `: ${value.toLocaleString()} (${percentage.toFixed(2)}%)`;
            }
            return label;
          }
        }
      }
    },
  };


  return (
    <div className="chart-container">
      <Line options={options} data={chartData} />
    </div>
  );
};

export default ForecastChart;
