import React from 'react';

const ForecastPercentageDisplay = ({ forecastValue, percentIncreases }) => {
  // Format the value to a dollar string
  const formatDollarValue = (value) => `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;

  // Format the percentage to a string rounded to the nearest tenth
  const formatPercentage = (value) => `${(Math.round(value * 10) / 10).toFixed(1)}%`;

  const timeFrames = [
    { months: 6, label: '6 Months' },
    { months: 12, label: '12 Months' },
    { months: 24, label: '2 Years' },
    { months: 60, label: '5 Years' },
    { months: 120, label: '10 Years' },
    { months: 360, label: '30 Years' },
  ];

  return (
    <div className="forecast-percentage-display">
      {timeFrames.map((timeFrame) => (
        <div key={timeFrame.label} className="forecast-value">
          <label>{timeFrame.label}:</label>
          <span>
            {forecastValue && forecastValue[timeFrame.months.toString()]  
              ? formatDollarValue(forecastValue[timeFrame.months.toString()])
              : 'N/A'
            }
          </span>
          <span>
            {percentIncreases && percentIncreases[timeFrame.months.toString()]
              ? `(${formatPercentage(percentIncreases[timeFrame.months.toString()])})`
              : 'N/A'
            }
          </span>
        </div>
      ))}
    </div>
  );
};

export default ForecastPercentageDisplay;
