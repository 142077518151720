import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Layout from './Layout';

const customMarkerIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41]
  });  

const MapView = () => {
    const position = [40.718871, -111.881845];; // Example position, replace with your own

  // Array of property data (replace with your data source)
  const properties = [
    {
      id: "2104027520",
      price: 450000,
      address: "1494 S Richards St W, Salt Lake City, UT 84115",
      coordinates: [40.737056, -111.892878]
    },
    {
      id: "2336238148",
      price: 415000,
      address: "3855 S 400 E, South Salt Lake, UT 84115",
      coordinates: [40.688139, -111.879542]
    },
    {
      id: "9129877714",
      price: 499990,
      address: "3464 S Arabella Ct E Unit 16, South Salt Lake, UT 84115",
      coordinates: [40.696026, -111.887024]
    },
    {
      id: "1605767347",
      price: 515000,
      address: "134 E Commonwealth Ave, Salt Lake City, UT 84115",
      coordinates: [40.724179, -111.886983]
    },
    {
      id: "1791777410",
      price: 425000,
      address: "325 E Utopia Ave, South Salt Lake, UT 84115",
      coordinates: [40.723617, -111.881634]
    },
    {
      id: "1414518530",
      price: 475000,
      address: "208 E Westminster Ave, Salt Lake City, UT 84115",
      coordinates: [40.728556, -111.885043]
    },
    {
      id: "1173626004",
      price: 550000,
      address: "295 E Snowy River Ct, Salt Lake City, UT 84115",
      coordinates: [40.704886, -111.883339]
    },
    {
      id: "2472553053",
      price: 399000,
      address: "203 E Southgate Ave, South Salt Lake, UT 84115",
      coordinates: [40.712046, -111.885249]
    },
    {
      id: "2527659753",
      price: 429900,
      address: "264 E 2700 S, Salt Lake City, UT 84115",
      coordinates: [40.712389, -111.883689]
    },
    {
      id: "9109886348",
      price: 480000,
      address: "3461 S State St E Unit 2A, South Salt Lake, UT 84115",
      coordinates: [40.695805, -111.887924]
    },
    {
      id: "1329546708",
      price: 460000,
      address: "3723 S Broughtyferry Cv, South Salt Lake, UT 84115",
      coordinates: [40.690482, -111.895863]
    },
    {
      id: "1501028527",
      price: 499900,
      address: "1760 S 300 E, Salt Lake City, UT 84115",
      coordinates: [40.732115, -111.882821]
    },
    {
      id: "2704720395",
      price: 410000,
      address: "325 E 2400 S, Salt Lake City, UT 84115",
      coordinates: [40.718871, -111.881845]
    },
    {
      id: "9063772775",
      price: 399900,
      address: "32 W 1700 S Unit A1, Salt Lake City, UT 84115",
      coordinates: [40.733639, -111.891885]
    },
    {
      id: "2612671591",
      price: 399999,
      address: "543 W Fine Dr, Salt Lake City, UT 84115",
      coordinates: [40.690362, -111.906728]
    }
  ];
  

  return (
    <Layout>
      <MapContainer center={position} zoom={13} className="map-container" style={{ height: '100vh', width: '100%' }}>
        <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors, &copy; <a href='https://carto.com/attributions'>CARTO</a>"
        />

        {properties.map(property => (
          <Marker key={property.id} position={property.coordinates} icon={customMarkerIcon}>
            <Popup>
              ${property.price.toLocaleString()} <br /> {property.address}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </Layout>
  );
};

export default MapView;
