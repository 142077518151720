import React, { useState } from 'react';
import { useCalculations } from '../CalculationsContext';

const Renovation = () => {
  const { renovationCost, setRenovationCost } = useCalculations();
  const { afterRepairValue, setAfterRepairValue } = useCalculations();
  const { expectedAnnualGrowthRate, setExpectedAnnualGrowthRate } = useCalculations();
  const { defaultBackgroundColor } = useCalculations();
  const { customBackgroundColor } = useCalculations();

  return (
    <div>
      {/* Renovation & After Repair Value */}
      <h5>Renovation & After Repair Value</h5>

      {/* Renovation Cost Input */}
      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Renovation Cost:</label>
          <input
            type="number"
            value={renovationCost || ''}
            onChange={(e) => setRenovationCost(Number(e.target.value))}
            className="form-control ml-2 input-field"
            placeholder="Enter renovation cost"
          />
        </div>
      </div>

      {/* After Repair Value (ARV) Input */}
      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">After Repair Value (ARV):</label>
          <input
            type="number"
            value={afterRepairValue || ''}
            onChange={(e) => setAfterRepairValue(Number(e.target.value))}
            className="form-control ml-2 input-field"
            placeholder="Enter ARV"
          />
        </div>
      </div>

      {/* Expected Annual Growth Rate Input */}
      <div className="mb-3 d-flex align-items-center">
       <div className="mb-3 label-input-group">
          <label className="form-label ml-3 mr-3 metric-label">Annual Growth:</label>
          <input 
            type="number" 
            value={expectedAnnualGrowthRate || ''} 
            onChange={(e) => setExpectedAnnualGrowthRate(Number(e.target.value))} 
            className="form-control ml-2 input-field"
            style={{  
              backgroundColor: expectedAnnualGrowthRate === 5 ? defaultBackgroundColor : customBackgroundColor
            }}
          />
        </div>
        <span className="ml-2"></span>
      </div>
    </div>
  );
};

export default Renovation;
