// AnnualExpenses.jsx

import React, { useState } from 'react';
import { useCalculations } from '../CalculationsContext';

const AnnualExpenses = () => {
  const { propertyTaxes, setPropertyTaxes } = useCalculations();
  const { homeInsurance, setHomeInsurance } = useCalculations();
  const { maintenance, setMaintenance } = useCalculations();
  const { utilities, setUtilities } = useCalculations();
  const { capexReserve, setCapexReserve } = useCalculations();

  return (
    <div>
      <h5>Annual Expenses</h5>
      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Property Tax:</label>
          <input 
            type="number" 
            value={propertyTaxes} 
            onChange={(e) => setPropertyTaxes(Number(e.target.value))} 
            className="form-control ml-2 input-field"
          />
        </div>
      </div>

      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Home Insurance:</label>
          <input 
            type="number" 
            value={homeInsurance} 
            onChange={(e) => setHomeInsurance(Number(e.target.value))} 
            className="form-control ml-2 input-field"
          />
        </div>
      </div>

      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Maintenance:</label>
          <input 
            type="number" 
            value={maintenance} 
            onChange={(e) => setMaintenance(Number(e.target.value))} 
            className="form-control ml-2 input-field"
          />
        </div>
      </div>

      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Utilities:</label>
          <input 
            type="number" 
            value={utilities} 
            onChange={(e) => setUtilities(Number(e.target.value))} 
            className="form-control ml-2 input-field"
          />
        </div>
      </div>

      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">CapEx Reserve:</label>
          <input 
            type="number" 
            value={capexReserve} 
            onChange={(e) => setCapexReserve(Number(e.target.value))} 
            className="form-control ml-2 input-field"
          />
        </div>
      </div>
    </div>
  );
};

export default AnnualExpenses;
