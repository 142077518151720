import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

export const CalculationsContext = createContext();

export const CalculationsProvider = ({ children }) => {
    const calculateMonthlyMortgage = (propertyPrice, downPayment, interestRate, loanTerm) => {
        const monthlyInterestRate = interestRate / 100 / 12;
        const numberOfPayments = loanTerm * 12;
        const loanAmount = propertyPrice - downPayment;
        const monthlyPayment = (loanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
        return monthlyPayment;
    };

    const calculateMonthlyDownpaymentFinancing = (financedAmount, interestRate, termLength) => {
        const monthlyInterestRate = interestRate / 100 / 12;
        const numberOfPayments = termLength * 12;
        const monthlyPayment = (financedAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
        return monthlyPayment;
    };

    const [propertyName, setPropertyName] = useState("Enter Property Name");
    const defaultBackgroundColor = "#e6f7ff"; // Light blue background for default values
    const customBackgroundColor = "#ffffff";  // White background for custom values
    const [showDownpaymentFinancingModal, setShowDownpaymentFinancingModal] = useState(false);
    const [isDownpaymentFinanced, setIsDownpaymentFinanced] = useState(false);  // New state
    const [financedDownpaymentAmount, setFinancedDownpaymentAmount] = useState(0);
    const [propertyPrice, setPropertyPrice] = useState(500000); // Default sale price
    const [downPayment, setDownPayment] = useState(0.2 * propertyPrice); // Default downpayment
    const [loanTerm, setLoanTerm] = useState(30); // Default loan term length in years
    const [interestRate, setInterestRate] = useState(5); // Default interest rate set to 5%
    const [closingCosts, setClosingCosts] = useState(0.04 * (propertyPrice - downPayment)); // Default closing costs set to $0
    const [downpaymentFinancedInterestRate, setDownpaymentFinancedInterestRate] = useState(10); // Defaulted to 10%
    const [downpaymentFinancedTermLength, setDownpaymentFinancedTermLength] = useState(10); // Defaulted to 10 years
    const [monthlyDownpaymentFinancedPayment, setMonthlyDownpaymentFinancedPayment] = useState(0);
    const [renovationCost, setRenovationCost] = useState(null);
    const [afterRepairValue, setAfterRepairValue] = useState(null);
    const [monthlyRent, setMonthlyRent] = useState(0);
    const [additionalIncome, setAdditionalIncome] = useState(null);
    const [vacancyRate, setVacancyRate] = useState(6.3);
    const [propertyTaxes, setPropertyTaxes] = useState(.011 * propertyPrice);
    const [hoaFees, setHoaFees] = useState(null);
    const [utilities, setUtilities] = useState(null);
    const [propertyManagement, setPropertyManagement] = useState(null);
    const [holdingYears, setHoldingYears] = useState(10); // Defaulted to 10 years
    const [useCustomDownPayment] = useState(false);
    const [expectedAnnualGrowthRate, setExpectedAnnualGrowthRate] = useState(5);
    const [expectedAnnualGrowthRateRent, setExpectedAnnualGrowthRateRent] = useState(5);
    const [expectedAnnualGrowthRateHOA, setExpectedAnnualGrowthRateHOA] = useState(3.5);
    const [monthlyGrossIncome, setMonthlyGrossIncome] = useState(0);
    const [monthlyOperatingExpenses, setMonthlyOperatingExpenses] = useState(0);
    const [monthlyNOI, setMonthlyNOI] = useState(0);
    const [monthlyCashFlow, setMonthlyCashFlow] = useState(0);
    const [annualCashFlow, setAnnualCashFlow] = useState(0);
    const [cashOnCashReturn, setCashOnCashReturn] = useState(0);
    const [annualizedROI, setAnnualizedROI] = useState(0);
    const annualNOI = monthlyNOI * 12;
    const capRate = annualNOI !== 0 ? (annualNOI / propertyPrice) * 100 : 0;
    const [monthlyMortgage, setMonthlyMortgage] = useState(0);
    const annualDebtService = monthlyMortgage * 12;
    const dscr = annualDebtService !== 0 ? annualNOI / annualDebtService : 0;
    const [grm, setGRM] = useState(0);
    const [equityBuildUpRate, setEquityBuildUpRate] = useState(0);
    const [totalROI, setTotalROI] = useState(0);
    const [monthlyInterestPayment, setMonthlyInterestPayment] = useState(0);
    const [annualGrossIncome, setAnnualGrossIncome] = useState(0);
    const [annualOperatingExpenses, setAnnualOperatingExpenses] = useState(0);
    const [firstYearPropertyAppreciation, setFirstYearPropertyAppreciation] = useState(0);
    const [annualRentAppreciation, setAnnualRentAppreciation] = useState(0);
    const [userId, setUserId] = useState(null);

    const handleChange = (event) => {
        const newPropertyPrice = Number(event.target.value);
        setPropertyPrice(newPropertyPrice);
        if (!useCustomDownPayment) {
            setDownPayment(0.2 * newPropertyPrice); // Automatically update downpayment if it's not custom
        }
    };

    const handleDownPaymentChange = (event) => {
        setDownPayment(Number(event.target.value));
    };

    const handleLoanTermChange = (event) => {
        setLoanTerm(Number(event.target.value));
    };

    const handleInterestRateChange = (event) => {
        setInterestRate(Number(event.target.value));
    };

    const handleClosingCostsChange = (event) => {
        setClosingCosts(Number(event.target.value));
    };

    // Recalculate mortgage whenever necessary values change
    useEffect(() => {
        const mortgage = calculateMonthlyMortgage(propertyPrice, downPayment, interestRate, loanTerm);
        setMonthlyMortgage(mortgage);
        const interestPayment = (interestRate / 100 / 12) * (propertyPrice - downPayment);
        setMonthlyInterestPayment(interestPayment);
    }, [propertyPrice, downPayment, interestRate, loanTerm]);

    useEffect(() => {
        const financedPayment = calculateMonthlyDownpaymentFinancing(financedDownpaymentAmount, downpaymentFinancedInterestRate, downpaymentFinancedTermLength);
        setMonthlyDownpaymentFinancedPayment(financedPayment);
    }, [financedDownpaymentAmount, downpaymentFinancedInterestRate, downpaymentFinancedTermLength]);

    useEffect(() => {
        const grossIncome = (monthlyRent || 0) + (additionalIncome || 0);
        setMonthlyGrossIncome(grossIncome);
    }, [monthlyRent, additionalIncome]);

    useEffect(() => {
        const operatingExpenses = (utilities || 0) + (hoaFees || 0) + (propertyManagement || 0);
        setMonthlyOperatingExpenses(operatingExpenses);
    }, [utilities, hoaFees, propertyManagement]);

    useEffect(() => {
        const noi = monthlyGrossIncome - monthlyOperatingExpenses;
        setMonthlyNOI(noi);
    }, [monthlyGrossIncome, monthlyOperatingExpenses]);

    useEffect(() => {
        const cashFlow = monthlyNOI - monthlyMortgage - monthlyDownpaymentFinancedPayment;
        setMonthlyCashFlow(cashFlow);
    }, [monthlyNOI, monthlyMortgage, monthlyDownpaymentFinancedPayment]);

    useEffect(() => {
        const annualFlow = monthlyCashFlow * 12;
        setAnnualCashFlow(annualFlow);
    }, [monthlyCashFlow]);

    useEffect(() => {
        const totalCashInvested = downPayment + closingCosts + (renovationCost || 0);
        const cocReturn = (annualCashFlow / totalCashInvested) * 100;
        setCashOnCashReturn(cocReturn);
    }, [downPayment, closingCosts, renovationCost, annualCashFlow]);

    useEffect(() => {
        const endValue = afterRepairValue || propertyPrice * Math.pow(1 + (expectedAnnualGrowthRate / 100), holdingYears);
        const totalCashInvested = downPayment + closingCosts + (renovationCost || 0);
        const annualizedROICalc = Math.pow((endValue / totalCashInvested), 1 / holdingYears) - 1;
        setAnnualizedROI(annualizedROICalc * 100);
    }, [downPayment, closingCosts, renovationCost, afterRepairValue, holdingYears]);

    useEffect(() => {
        // Annual Metrics to recalculate
        const annualGrossIncome = monthlyGrossIncome * 12;
        const annualOperatingExpenses = monthlyOperatingExpenses * 12;
        const firstYearPropertyAppreciation = afterRepairValue * (expectedAnnualGrowthRate / 100);
        const annualRentAppreciation = (monthlyRent * 12) * (expectedAnnualGrowthRateRent / 100);

        setAnnualGrossIncome(annualGrossIncome);
        setAnnualOperatingExpenses(annualOperatingExpenses);
        setFirstYearPropertyAppreciation(firstYearPropertyAppreciation);
        setAnnualRentAppreciation(annualRentAppreciation);
      
        const annualPrincipalPayments = (monthlyMortgage - monthlyInterestPayment) * 12;
        const totalCashInvested = downPayment + closingCosts + (renovationCost || 0);
        const appreciation = afterRepairValue ? (afterRepairValue - propertyPrice) / holdingYears : propertyPrice * (expectedAnnualGrowthRate / 100);
        const taxBenefits = 0; // Assuming no specific tax benefits for simplicity

        const calculatedTotalROI = ((annualCashFlow + annualPrincipalPayments + appreciation + taxBenefits) / totalCashInvested) * 100;
        setTotalROI(calculatedTotalROI);

    }, [propertyPrice, monthlyRent, downPayment, closingCosts, renovationCost, afterRepairValue, holdingYears, annualCashFlow, monthlyMortgage, monthlyInterestPayment, monthlyGrossIncome, monthlyOperatingExpenses, expectedAnnualGrowthRateRent, expectedAnnualGrowthRate]);

      const gatherPropertyData = () => {
        return {
          userId,
          propertyName,
          propertyPrice,
          downPayment,
          loanTerm,
          interestRate,
          closingCosts,
          downpaymentFinancedInterestRate,
          downpaymentFinancedTermLength,
          monthlyDownpaymentFinancedPayment,
          renovationCost,
          afterRepairValue,
          monthlyRent,
          additionalIncome,
          vacancyRate,
          propertyTaxes,
          hoaFees,
          utilities,
          propertyManagement,
          holdingYears,
          expectedAnnualGrowthRate,
          expectedAnnualGrowthRateRent,
          expectedAnnualGrowthRateHOA,
          monthlyGrossIncome,
          monthlyOperatingExpenses,
          monthlyNOI,
          monthlyCashFlow,
          annualCashFlow,
          cashOnCashReturn,
          annualizedROI,
          monthlyMortgage,
          grm,
          equityBuildUpRate,
          totalROI,
          monthlyInterestPayment,
          annualGrossIncome,
          annualOperatingExpenses,
          firstYearPropertyAppreciation,
          annualRentAppreciation,
          capRate
        };
      };

    return (
        <CalculationsContext.Provider 
            value={{
            userId, setUserId, 
            propertyName, setPropertyName,
            propertyPrice, setPropertyPrice,
            downPayment, setDownPayment,
            loanTerm, setLoanTerm,
            interestRate, setInterestRate,
            closingCosts, setClosingCosts,
            downpaymentFinancedInterestRate, setDownpaymentFinancedInterestRate,
            downpaymentFinancedTermLength, setDownpaymentFinancedTermLength,
            monthlyDownpaymentFinancedPayment, setMonthlyDownpaymentFinancedPayment,
            renovationCost, setRenovationCost,
            afterRepairValue, setAfterRepairValue,
            monthlyRent, setMonthlyRent,
            additionalIncome, setAdditionalIncome,
            vacancyRate, setVacancyRate,
            propertyTaxes, setPropertyTaxes,
            hoaFees, setHoaFees,
            utilities, setUtilities,
            propertyManagement, setPropertyManagement,
            holdingYears, setHoldingYears,
            expectedAnnualGrowthRate, setExpectedAnnualGrowthRate,
            expectedAnnualGrowthRateRent, setExpectedAnnualGrowthRateRent,
            expectedAnnualGrowthRateHOA, setExpectedAnnualGrowthRateHOA,
            monthlyGrossIncome, setMonthlyGrossIncome,
            monthlyOperatingExpenses, setMonthlyOperatingExpenses,
            monthlyNOI, setMonthlyNOI,
            monthlyCashFlow, setMonthlyCashFlow,
            annualCashFlow, setAnnualCashFlow,
            cashOnCashReturn, setCashOnCashReturn,
            annualizedROI, setAnnualizedROI,
            monthlyMortgage, setMonthlyMortgage,
            grm, setGRM,
            equityBuildUpRate, setEquityBuildUpRate,
            totalROI, setTotalROI,
            monthlyInterestPayment, setMonthlyInterestPayment,
            annualGrossIncome, setAnnualGrossIncome,
            annualOperatingExpenses, setAnnualOperatingExpenses,
            firstYearPropertyAppreciation, setFirstYearPropertyAppreciation,
            annualRentAppreciation, setAnnualRentAppreciation,
            calculateMonthlyMortgage,
            calculateMonthlyDownpaymentFinancing,
            defaultBackgroundColor,
            customBackgroundColor,
            gatherPropertyData
            // Add any other state variables or functions you want to expose
            }}
        >
            {children}
        </CalculationsContext.Provider>
        );
    };

export const useCalculations = () => {
    const context = useContext(CalculationsContext);
    if (!context) {
        throw new Error('useCalculations must be used within a CalculationsProvider');
    }
    return context;
    };
