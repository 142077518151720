import React, { useState } from 'react';
import { useCalculations } from '../CalculationsContext';
import axios from 'axios';
import ForecastChart from './ForecastChart';
import { useForecast } from './ForecastContext';
import config from '../config';

const PriceEstimateSearch = () => {
    const { setPropertyPrice } = useCalculations();
    const { setForecastId } = useForecast();
    const { setForecastDate } = useForecast();
    
    const [address, setAddress] = useState('');
    const [averageEstimate, setAverageEstimate] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [forecastData, setForecastData] = useState(null);

    const fetchEstimate = async () => {
        // Reset previous results and errors
        setAverageEstimate(null);
        setErrorMessage('');
       
        try {
            const response = await axios.post(`${config.API_BASE_URL}/scrapers/scrape/`, { address: address });
            if (response.data && response.data.average_estimate) {
                setAverageEstimate(response.data.average_estimate);
                /*console.log('Average Estimate:', response.data.address); // Debugging line*/
                // Assume response.data contains the forecast ID
                setForecastId(response.data.forecastId);
                setForecastDate(response.data.forecastDate); // Store the forecast ID
                console.log('Forecast ID:', response.data.forecastId); // Debugging line*/
                // Call fetchForecast with the forecast ID
                /*fetchForecast(response.data.forecast_id);*/
            } else {
                console.error('Failed to fetch average estimate.');
                setErrorMessage('Property not found');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorMessage('Property not found');
        }
    };

    const fetchForecast = async (forecastId) => {
        try {
            const forecastResponse = await axios.get(`${config.API_BASE_URL}/get-forecast/${forecastId}`); // Use forecast ID instead of address
            if (forecastResponse.data && forecastResponse.data.average_forecast) {
                setForecastData(forecastResponse.data);
            } else {
                console.error('Failed to fetch forecast data.');
            }
        } catch (error) {
            console.error('Error fetching forecast data:', error);
        }
    };

    return (
        <div>
            <h4>Get Property Estimate</h4>
            <input 
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter an address"
            />
            <button onClick={fetchEstimate}>Search</button>

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

            {averageEstimate && (
                <div>
                    <p>Average Estimate: ${averageEstimate.toFixed(2)}</p>
                    <button onClick={() => setPropertyPrice(averageEstimate)}>
                        Use this estimate
                    </button>
                </div>
            )}

            {forecastData && <ForecastChart data={forecastData} address={address} />}
        </div>
    );
};

export default PriceEstimateSearch;