import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthenticationContext } from './AuthenticationContext';
import ComparisonView from './ComparisonView';
import config from '../config';


export const fetchSavedProperties = async (setInvestmentProfiles, currentUserId) => {
    try {
        const response = await axios.get(`${config.API_BASE_URL}/get_investment_profiles?user_id=${currentUserId}`, {
            withCredentials: true,
        });
        if (response.status === 200) {
            setInvestmentProfiles(response.data);
        }
    } catch (error) {
        console.error('There was a problem fetching the saved properties:', error);
    }
};

const InvestmentProfiles = ({ investmentProfiles, setInvestmentProfiles, toggleCalculatorCard }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { isAuthenticated, propertyCount, currentUserId } = useContext(AuthenticationContext);
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [view, setView] = useState('propertyList'); // State to control the view

    useEffect(() => {
        setIsLoading(true);

        const fetchData = async () => {
            try {
                await fetchSavedProperties(setInvestmentProfiles, currentUserId);
            } catch (err) {
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [currentUserId, setInvestmentProfiles]);

    const handleSelectProfile = (profile) => {
        if (selectedProfiles.includes(profile.analysisId)) {
            setSelectedProfiles(selectedProfiles.filter(id => id !== profile.analysisId));
        } else {
            setSelectedProfiles([...selectedProfiles, profile.analysisId]);
        }
    };
    

    const handleDelete = async () => {
        if (selectedProfiles.length === 0) {
            alert('Please select at least one profile to delete.');
            return;
        }
    
        // Prepare the data for deletion
        const profilesToDelete = selectedProfiles.map(id => {
            // Find the profile with the matching analysisId
            const profile = investmentProfiles.find(p => p.analysisId === id);
            // Check if the profile was found
            if (profile) {
                // Return the analysisId and timestamp for deletion
                return { analysisId: profile.analysisId, timestamp: profile.timestamp };
            }
            return null;
        }).filter(p => p !== null);  // Filter out any null values
    
        if (profilesToDelete.length === 0) {
            alert('No matching profiles found for deletion.');
            return;
        }
    
        console.log('Profiles to delete:', profilesToDelete);

        try {
            const response = await axios.delete(`${config.API_BASE_URL}/delete_properties`, {
                data: profilesToDelete,  // Send the array directly
                withCredentials: true
            });
        
            if (response.status === 200) {
                alert('Selected profiles deleted successfully.');
                // Remove the deleted profiles from the state
                setInvestmentProfiles(investmentProfiles.filter(profile => !selectedProfiles.includes(profile.analysisId)));
                // Clear the selected profiles
                setSelectedProfiles([]);
            }
        } catch (error) {
            console.error('There was a problem deleting the selected profiles:', error);
            alert('Failed to delete selected profiles. Please try again.');
        }
    };    

    const handleCompare = () => {
        console.log('Compare these profiles:', selectedProfiles);
        setView('comparison');
        toggleCalculatorCard(false); // Hide calculator card on compare view
    };

    const handleReturnToPropertyList = () => {
        setView('propertyList');
        toggleCalculatorCard(true); // Show calculator card on property list view
    };

    if (view === 'propertyList') {
        return (
                <div className="investment-profiles">
                    {investmentProfiles && investmentProfiles.length > 0 ? (
                        <div className="table-container">
                            <div className="table-actions table-actions-bg">
                                <button className="btn btn-custom-danger btn-left-justify" onClick={handleDelete}>Delete Selected</button>
                                <button className="btn btn-custom-primary btn-left-justify" onClick={handleCompare}>Compare Selected</button>
                            </div>
                            <table className="table table-bordered table-custom">
                                <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>Property Name</th>
                                        <th>Price</th>
                                        <th>Holding Years</th>
                                        <th>Annualized ROI</th>
                                        <th>Cap Rate</th>
                                        <th>Total ROI</th>
                                        <th>Monthly Gross Income</th>
                                        <th>Monthly NOI</th>
                                        <th>Monthly Cash Flow</th>
                                        <th>Annual Cash Flow</th>
                                        <th>COCR</th>
                                        <th>Annual Gross Income</th>
                                        <th>Annual Operating Expenses</th>
                                        <th>First Year Property Appreciation</th>
                                        <th>First Year Rent Appreciation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {investmentProfiles.map((profile, index) => (
                                        <tr key={profile.propertyId || index}>
                                            <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedProfiles.includes(profile.analysisId)}
                                                onChange={() => handleSelectProfile(profile)}
                                            />

                                            </td>
                                            <td>{profile.propertyName}</td>
                                            <td>{profile.propertyPrice}</td>
                                            <td>{profile.holdingYears}</td>
                                            <td>{profile.annualizedROI}</td>
                                            <td>{profile.capRate}</td>
                                            <td>{profile.totalROI}</td>
                                            <td>{profile.monthlyGrossIncome}</td>
                                            <td>{profile.monthlyNOI}</td>
                                            <td>{profile.monthlyCashFlow}</td>
                                            <td>{profile.annualCashFlow}</td>
                                            <td>{profile.cashOnCashReturn} %</td>
                                            <td>{profile.annualGrossIncome}</td>
                                            <td>{profile.annualOperatingExpenses}</td>
                                            <td>{profile.firstYearPropertyAppreciation}</td>
                                            <td>{profile.annualRentAppreciation}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>No investment profiles available. Please save a property to view profiles.</div>
                    )}
                </div>
            );    
            } else if (view === 'comparison') {
                return (
                    <div className="investment-profiles">
                        <button onClick={handleReturnToPropertyList}>Back to Property List</button>
                        <ComparisonView selectedProfiles={selectedProfiles} investmentProfiles={investmentProfiles} />
                    </div>
                );
            } 
        };
export default InvestmentProfiles;
