import React, { createContext, useState } from 'react';

const ForecastContext = createContext();

export const ForecastProvider = ({ children }) => {
    const [forecastId, setForecastId] = useState(null);
    const [forecastDate, setForecastDate] = useState(null);

    return (
        <ForecastContext.Provider value={{ 
            forecastId, setForecastId,
            forecastDate, setForecastDate
         }}>
            {children}
        </ForecastContext.Provider>
    );
};

export const useForecast = () => {
    const context = React.useContext(ForecastContext);
    if (!context) {
        throw new Error('useForecast must be used within a ForecastProvider');
    }
    return context;
};
