// Layout.jsx
import React from 'react';
import NavBar from './NavBar';
import newLogo from '../new-logo.svg';
import { useAuthentication } from './AuthenticationContext';

const Layout = ({ children }) => {
  const { setShowLoginPopup } = useAuthentication();

  return (
    <div className="App">
      <head> 
        <meta name="viewport" content="width=device-width, initial-scale=1" /> 
      </head>
      <NavBar />
      <header className="App-logo-header">
        <a href="/">
          <img src={newLogo} className="App-logo" alt="logo" /> 
        </a>
        <button className="login-button" onClick={() => setShowLoginPopup(true)}>
          Login
        </button>
      </header>
      <div className="container-fluid">
        {children}
      </div>
    </div>
  );
};

export default Layout;
