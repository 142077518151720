// NavBar.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaChartBar, FaMapMarkedAlt } from 'react-icons/fa';

const NavBar = () => {
  return (
    <div className="nav-bar">
      <NavLink to="/" className="nav-item" end>
        <FaHome className="nav-icon" />
      </NavLink>
      <NavLink to="/forecast" className="nav-item">
        <FaChartBar className="nav-icon" />
      </NavLink>
      <NavLink to="/map" className="nav-item">
        <FaMapMarkedAlt className="nav-icon" />
      </NavLink>
    </div>
  );
};

export default NavBar;
