import React, { useState } from 'react';
import { useCalculations } from '../CalculationsContext';
import { Modal, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const PurchaseCosts = () => {
  const { propertyName, setPropertyName } = useCalculations();
  const { propertyPrice, setPropertyPrice } = useCalculations();
  const { downPayment, setDownPayment } = useCalculations();
  const { loanTerm, setLoanTerm } = useCalculations();
  const { interestRate, setInterestRate } = useCalculations();
  const { closingCosts, setClosingCosts } = useCalculations();
  const { monthlyMortgage, setMonthlyMortgage } = useCalculations();
  const { isDownpaymentFinanced, setIsDownpaymentFinanced } = useCalculations();
  const { financedDownpaymentAmount, setFinancedDownpaymentAmount } = useCalculations();
  const { downpaymentFinancedInterestRate, setDownpaymentFinancedInterestRate } = useCalculations();
  const { downpaymentFinancedTermLength, setDownpaymentFinancedTermLength } = useCalculations();
  const { monthlyDownpaymentFinancedPayment, setMonthlyDownpaymentFinancedPayment } = useCalculations();
  const {handleDownPaymentChange} = useCalculations();
  const {defaultBackgroundColor} = useCalculations();
  const {customBackgroundColor} = useCalculations();
  const {showDownpaymentFinancingModal, setShowDownpaymentFinancingModal} = useCalculations();


  return (
    <div>
      <h5>Purchase Costs</h5>

      {/* New Field for Property Name */}
      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Property Name:</label>
          <input 
            type="text"
            value={propertyName}
            onChange={(e) => setPropertyName(e.target.value)}
            className="form-control ml-2 input-field"
            placeholder="Enter Property Name"
          />
        </div>
      </div>
      
      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Property Price: $</label>
          <input 
            type="number" 
            value={propertyPrice} 
            onChange={(e) => setPropertyPrice(e.target.value)} 
            className="form-control ml-2 input-field" 
          />
        </div>
      </div>

      {/* Downpayment Input */}
      <div className="mb-3">
        <div className="d-flex align-items-center">
          <div className="mb-3 label-input-group">
            <label className="form-label mr-3 metric-label">Downpayment $:</label>
            <input 
              type="number" 
              value={downPayment} 
              onChange={handleDownPaymentChange} 
              className="form-control ml-2 input-field"
              style={{ 
                width: '100px', 
                backgroundColor: downPayment === 0.2 * propertyPrice ? defaultBackgroundColor : customBackgroundColor 
              }}
            />
          </div>
        </div>
        <div className="mt-2">
          <Button 
            variant={isDownpaymentFinanced ? "success" : "outline-secondary"} 
            className="finance-bttn" 
            onClick={() => setShowDownpaymentFinancingModal(true)}
          >
            {isDownpaymentFinanced 
                ? `Financed: $${financedDownpaymentAmount} @ ${downpaymentFinancedInterestRate}% for ${downpaymentFinancedTermLength} years`
                : "Finance Downpayment?"}
          </Button>
          {isDownpaymentFinanced && (
            <small className="text-muted mt-1 d-block">
                Monthly Payment: ${monthlyDownpaymentFinancedPayment.toFixed(2)}
            </small>
          )}
        </div>
      </div>

      {/* Bootstrap Modal for Financing Details */}
      <Modal show={showDownpaymentFinancingModal} onHide={() => setShowDownpaymentFinancingModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Downpayment Financing Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label>Financed Downpayment Amount:</label>
            <input 
              type="number" 
              value={financedDownpaymentAmount} 
              onChange={(e) => setFinancedDownpaymentAmount(e.target.value)} 
              className="form-control input-field"
              placeholder="Enter financed downpayment amount"
            />
          </div>
          <div className="mb-3">
            <label>Downpayment Financing Interest Rate:</label>
            <input 
              type="number" 
              value={downpaymentFinancedInterestRate} 
              onChange={(e) => setDownpaymentFinancedInterestRate(Number(e.target.value))}
              className="form-control input-field"
              placeholder="Enter interest rate"
            />
          </div>
          <div className="mb-3">
            <label>Downpayment Financing Term Length (years):</label>
            <input 
              type="number" 
              value={downpaymentFinancedTermLength} 
              onChange={(e) => setDownpaymentFinancedTermLength(Number(e.target.value))}
              className="form-control input-field"
              placeholder="Enter term length in years"
            />
          </div>
          <div className="mb-3">
            <label>Estimated Monthly Payment for Financed Downpayment:</label>
            <div>${monthlyDownpaymentFinancedPayment.toFixed(2)}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDownpaymentFinancingModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => {
              setShowDownpaymentFinancingModal(false);
              setIsDownpaymentFinanced(true);
          }}>
              Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      
      {/*<div className="mb-3 d-flex align-items-center">
        <label className="form-label mr-3">Down Payment: $</label>
        <input type="number" value={downPayment} onChange={(e) => setDownPayment(e.target.value)} className="form-control ml-2" style={{ width: '150px' }} />
        </div>*/}
      
      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">  
          <label className="form-label mr-3 metric-label">Loan Term (years):</label>
          <input 
            type="number" 
            value={loanTerm} 
            onChange={(e) => setLoanTerm(e.target.value)} 
            className="form-control ml-2 input-field" 
          />
        </div>
      </div>

      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Interest Rate (%):</label>
          <input 
            type="number" 
            value={interestRate} 
            onChange={(e) => setInterestRate(e.target.value)} 
            className="form-control ml-2 input-field" 
          />
        </div>
      </div>

      {/* Monthly Mortgage Display */}
      <div className="d-flex align-items-center mb-3">
        <label className="form-label mr-3 metric-label">Estimated Monthly Mortgage:</label>
        <div>
            ${monthlyMortgage.toFixed(2)}
        </div>
      </div>

      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Closing Costs: $</label>
          <input 
            type="number" 
            value={closingCosts} 
            onChange={(e) => setClosingCosts(e.target.value)} 
            className="form-control ml-2 input-field"
          />
        </div>
      </div>
      
    </div>
  );
};

export default PurchaseCosts;
