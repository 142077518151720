let API_BASE_URL;

if (process.env.REACT_APP_ENV === 'production') {
    API_BASE_URL = 'https://api.kpicomp.com';
} else {
    API_BASE_URL = 'http://localhost:8001';
}

const config = {
  API_BASE_URL
};

export default config;