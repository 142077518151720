import React from 'react';
import PropTypes from 'prop-types';
import { useCalculations } from '../CalculationsContext';

const MonthlyIncome = () => {
  const { monthlyRent, setMonthlyRent } = useCalculations();
  const { additionalIncome, setAdditionalIncome } = useCalculations();
  const { vacancyRate, setVacancyRate } = useCalculations();
  const { monthlyGrossIncome, setMonthlyGrossIncome } = useCalculations();

  return (
    <div>
      <h5>Monthly Income</h5>

      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Rent: $</label>
          <input
            type="number"
            value={monthlyRent || ''}
            onChange={(e) => setMonthlyRent(Number(e.target.value))}
            className="form-control ml-2 input-field"
            placeholder="Enter monthly rent"
          />
        </div>
      </div>

      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Additional Income: $</label>
          <input
            type="number"
            value={additionalIncome || ''}
            onChange={(e) => setAdditionalIncome(Number(e.target.value))}
            className="form-control ml-2 input-field"
            placeholder="Enter Additional Income"
          />
        </div>
      </div>

      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Vacancy Rate: </label>
          <input 
              type="number" 
              value={vacancyRate || ''} 
              onChange={(e) => setVacancyRate(Number(e.target.value))} 
              className="form-control ml-2 input-field" 
              placeholder="Enter Vacancy Rate" 
          />
        </div>
      </div>

      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Gross Monthly Income: $</label>
          <input 
                type="number" 
                value={monthlyGrossIncome || '0'} 
                onChange={(e) => setMonthlyGrossIncome(Number(e.target.value))} 
                className="form-control ml-2 input-field" 
            />
          </div>
      </div>
    </div>
  );
};

export default MonthlyIncome;
