import React from 'react';
import { useCalculations } from '../CalculationsContext';

const AnnualizedKPIs = () => {
  const { holdingYears, setHoldingYears } = useCalculations();
  const { annualizedROI, setAnnualizedROI } = useCalculations();
  const { capRate } = useCalculations();
  const { dscr } = useCalculations();
  const { grm, setGRM } = useCalculations();
  const { equityBuildUpRate, setEquityBuildUpRate } = useCalculations();
  const { totalROI, setTotalROI } = useCalculations();

  return (
    <div className='annualized-kpis'>
      <div>
        <h4>Annualized Metrics (after {holdingYears} {holdingYears === 1 ? 'year' : 'years'})</h4>
        <div>
          <strong>Annualized ROI:</strong> {annualizedROI.toFixed(2)}%
        </div>
        <div>
          <strong>Cap Rate:</strong> {Number.isFinite(capRate) ? capRate.toFixed(2) : 'N/A'}%
        </div>
        <div>
          <strong>Debt Service Coverage Ratio (DSCR):</strong> {Number.isFinite(dscr) ? dscr.toFixed(2) : 'N/A'}%
        </div>
        <div>
          <strong>Gross Rent Multiplier:</strong> ${grm.toFixed(2)}
        </div>
        <div>
          <strong>Equity Build-Up Rate:</strong> ${equityBuildUpRate.toFixed(2)}
        </div>
        <div>
          <strong>Total ROI:</strong> {totalROI.toFixed(2)}%
        </div>
      </div>
    </div>
  );
};

export default AnnualizedKPIs;

