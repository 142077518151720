import React, { useState } from 'react';
import { useCalculations } from '../CalculationsContext';

const MonthlyExpenses = () => {
  const { hoaFees, setHoaFees } = useCalculations();
  const { utilities, setUtilities } = useCalculations();
  const { propertyManagement, setPropertyManagement } = useCalculations();
  const { expectedAnnualGrowthRateHOA, setExpectedAnnualGrowthRateHOA} = useCalculations();

  return (
    <div>
      <h5>Monthly Expenses</h5>

      {/* HOA Fees */}
      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">HOA Fees:</label>
          <input 
            type="number" 
            value={hoaFees || ''} 
            onChange={(e) => setHoaFees(Number(e.target.value))} 
            className="form-control ml-2 input-field"
          />
        </div>
      </div>

      {/* Utilities */}
      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Utilities:</label>
          <input 
            type="number" 
            value={utilities || ''} 
            onChange={(e) => setUtilities(Number(e.target.value))} 
            className="form-control ml-2 input-field"
          />
        </div>
      </div>

      {/* Property Management */}
      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label mr-3 metric-label">Property Management:</label>
          <input 
            type="number" 
            value={propertyManagement || ''}
            onChange={(e) => setPropertyManagement(Number(e.target.value))} 
            className="form-control ml-2 input-field"
          />
        </div>
      </div>

      {/* Expected Annual Growth Rate for HOA */}
      <div className="mb-3 d-flex align-items-center">
        <div className="mb-3 label-input-group">
          <label className="form-label ml-3 mr-3 metric-label">Annual Growth for HOA:</label>
          <input 
            type="number" 
            value={expectedAnnualGrowthRateHOA || ''} 
            onChange={(e) => setExpectedAnnualGrowthRateHOA(Number(e.target.value))} 
            className="form-control ml-2 input-field"
          />
        </div>
        <span className="ml-2"></span>
      </div>
    </div>
  );
};

export default MonthlyExpenses;