import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; 
import config from '../config';

export const AuthenticationContext = createContext();

export const useAuthentication = () => {
  return useContext(AuthenticationContext);
};

export const AuthenticationProvider = ({ children }) => {
  const [propertyCount, setPropertyCount] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [guestUserId, setGuestUserId] = useState(null);
  const [canvasData, setCanvasData] = useState(null)

  function generateCanvasFingerprint() {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    var text = 'Canvas Fingerprint';
    context.textBaseline = 'top';
    context.font = "14px 'Arial'";
    context.textBaseline = 'alphabetic';
    context.fillStyle = '#f60';
    context.fillRect(125,1,62,20);
    context.fillStyle = '#069';
    context.fillText(text, 2, 15);
    context.fillStyle = 'rgba(102, 204, 0, 0.7)';
    context.fillText(text, 4, 17);
    var canvasData = context.getImageData(0, 0, 200, 200).data;
    return canvasData;
  }

  const initializeGuestSession = async () => {
    try {
      const canvasData = generateCanvasFingerprint(); // Generate canvas fingerprint
      const response = await axios.get(`${config.API_BASE_URL}/init_guest_session`, {
        params: { canvasData }, // Include canvas data in the request parameters
        withCredentials: true
      });
      if (response.data.guestUserId) {
        setGuestUserId(response.data.guestUserId);
      }
    } catch (error) {
      console.error('Error initializing guest session:', error);
    }
  };

  useEffect(() => {
    initializeGuestSession();
  }, []);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/is-authenticated`, { withCredentials: true });
        if (response.data.is_authenticated) {
          setIsAuthenticated(true);
          setCurrentUserId(response.data.user_id); // Use authenticated user_id
        } else if (guestUserId) {
          setCurrentUserId(guestUserId); // Use guestUserId if not authenticated
        }
      } catch (error) {
        console.error('Error checking authentication status:', error);
      }
    };
  
    checkAuthentication();
  }, [guestUserId, canvasData]); 
  
  const handleGuestAction = async () => {
    if (propertyCount >= 2) {
      setShowLoginPopup(true);
    } else {
      setPropertyCount(propertyCount + 1);
  
      try {
        // Call the new backend route to track the guest action
        await axios.post(`${config.API_BASE_URL}/track_guest_action`, {}, { withCredentials: true });
      } catch (error) {
        console.error('Error tracking guest action:', error);
        // Handle the error appropriately, e.g., showing the login popup if the limit is exceeded
        if (error.response && error.response.status === 401) {
          setShowLoginPopup(true);
        }
      }
    }
  };

  const saveGuestUserActions = (guestUserId) => {
    // Implement logic to save guest user's actions
  };

  const transferGuestDataToUser = (userId) => {
    // Implement logic to transfer data from guestUserId to userId
  };

  const handleLogin = async (username, password) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/token`, {
        username,
        password,
      }, { withCredentials: true });
      
      if (response.status === 200 && response.data.access_token) {
        setIsAuthenticated(true);
        setShowLoginPopup(false);
        setCurrentUserId(response.data.user_id); // Assuming the user_id is returned from the server
        transferGuestDataToUser(response.data.user_id);
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const handleRegister = async (email, username, password) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/register`, {
        email,
        username,
        password
      }, { withCredentials: true });
      if (response.status === 200) {
        console.log('Registration successful');
        setShowLoginPopup(false);
        setIsAuthenticated(true);
        setCurrentUserId(response.data.userId);
        transferGuestDataToUser(response.data.userid);
      }
    } catch (error) {
      console.error('Registration failed:', error);
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setCurrentUserId(null);
  };
  
  return (
    <AuthenticationContext.Provider value={{
      guestUserId,
      isAuthenticated,
      setIsAuthenticated,
      guestUserId,
      handleLogin,
      handleRegister,
      handleLogout,
      propertyCount,
      setPropertyCount,
      showLoginPopup,
      setShowLoginPopup,
      handleGuestAction,
      currentUserId,
      setCurrentUserId,
      generateCanvasFingerprint,
      canvasData, 
      setCanvasData
    }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
