import React from 'react';

const calculatePercentageDifference = (value1, value2) => {
    if (value1 === 0 && value2 === 0) return 0;
    if (value1 === 0) return 100;
    return ((value2 - value1) / value1) * 100;
};

const ComparisonView = ({ selectedProfiles, investmentProfiles }) => {
    if (selectedProfiles.length !== 2) {
        return <div>Please select exactly two properties to compare.</div>;
    }

    const propertyA = investmentProfiles.find(profile => profile.analysisId === selectedProfiles[0]);
    const propertyB = investmentProfiles.find(profile => profile.analysisId === selectedProfiles[1]);

    if (!propertyA || !propertyB) {
        return <div>Unable to find selected properties for comparison.</div>;
    }

    const totalROIDifference = calculatePercentageDifference(propertyA.totalROI, propertyB.totalROI);


    return (
        <div className="comparison-view">
            <h3>Comparison</h3>
            <div className="comparison-grid">
                <div className="property-column">
                    <h4>{propertyA.propertyName}</h4>
                    {/* Display metrics for property A */}
                    <p>Total ROI: {propertyA.totalROI}</p>
                    {/* Other metrics can be added here */}
                </div>

                <div className="comparison-column">
                    <h4>Comparison</h4>
                    <p>Total ROI Difference: {totalROIDifference.toFixed(2)}%</p>
                    {/* Other comparison calculations can be added here */}
                </div>

                <div className="property-column">
                    <h4>{propertyB.propertyName}</h4>
                    {/* Display metrics for property B */}
                    <p>Total ROI: {propertyB.totalROI}</p>
                    {/* Other metrics can be added here */}
                </div>
            </div>
        </div>
    );
};

export default ComparisonView;