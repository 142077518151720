import React from 'react';

const Sidebar = ({ analyses, onSelectAnalysis, selectedAnalysisId }) => {
  return (
    <div className="col-md-2 forecast-side-panel">
      <div className="sidebar-card">
        <h3>Analyses</h3>
        <ul>
          {analyses.map((analysis) => (
            <li
              key={analysis.analysisId}
              onClick={() => onSelectAnalysis(analysis.analysisId)}
              className={selectedAnalysisId === analysis.analysisId ? "selected-analysis" : ""}
            >
              {analysis.propertyName}: ${Math.round(analysis.propertyPrice)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
