import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthenticationProvider, useAuthentication } from './components/AuthenticationContext';
import { ForecastProvider } from './components/ForecastContext';
import AppContent from './AppContent';
import ForecastingView from './components/ForecastingView';
import NavBar from './components/NavBar';
import MapView from './components/MapView';
import LoginPopup from './components/LoginPopup';

function App() {
  return (
    <Router>
      <AuthenticationProvider>
        <NavBar />
        <ForecastProvider>
          <Routes>
            <Route path="/" element={<AppContent />} />
            <Route path="/forecast" element={<ForecastingView />} /> 
            <Route path="/map" element={<MapView />} />
          </Routes>
        </ForecastProvider>
        <ConditionalLoginPopup />
      </AuthenticationProvider>
    </Router>
  );
}

function ConditionalLoginPopup() {
  const { showLoginPopup } = useAuthentication(); // This is now correctly scoped

  if (showLoginPopup) {
    return <LoginPopup />;
  }
  return null;
}

export default App;